import mitt from 'mitt';
import { authUtilityLoader } from "@consumer/auth-utility-loader";
globalThis.caiEventBus = globalThis.caiEventBus || mitt();

let authUtilityPromise = null;

async function loadAuthUtility() {
  if (!authUtilityPromise) {
    authUtilityPromise = await authUtilityLoader();
  }
  return authUtilityPromise;
}

async function updateSignedInStatus() {
  try {
    const { getIsUserSignedIn, getUserAttributes } = await loadAuthUtility();
    const isSignedIn = await getIsUserSignedIn();
    localStorage.setItem("isUserSignedIn", isSignedIn);

    if (isSignedIn) {
      const userAttributes = await getUserAttributes();
      localStorage.setItem("userProfile", JSON.stringify(userAttributes));
    }

    return isSignedIn;
  } catch (error) {
    console.error("Error updating signed in status:", error);
    return false;
  }
}


// Only load the auth utility when needed
function checkAuthStatus() {
  const isUserSignedIn = localStorage.getItem("isUserSignedIn");
  if (isUserSignedIn === null) {
    // Only update status if we don't have a stored value
    updateSignedInStatus();
  }
}

export const setAuthEvents = async () => {
  checkAuthStatus();
  caiEventBus.on("auth.signin", async () => {
    try {
      const { getUserAttributes } = await loadAuthUtility();
      const status = await updateSignedInStatus();

      if (status) {
        const userAttributes = await getUserAttributes();
        localStorage.setItem("userProfile", JSON.stringify(userAttributes));
        location.reload();
      }
    } catch (error) {
      console.error("Error signing in:", error);
    }
  });

  caiEventBus.on("auth.signout", () => {
    try {
      localStorage.setItem("isUserSignedIn", "false");
      localStorage.removeItem("userProfile");
    } catch (error) {
      console.error("Error signing out:", error);
    }
  });
}