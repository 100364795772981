'use client';

import React from 'react';
import useDataIsland from './useDataIsland';
import useDataLayer from './useDataLayer';
import psxBuyerLandingAnalyticsHandler from './psxBuyerLandingAnalyticsHandler';
import { AnalyticsEngine  } from 'reaxl-analytics';

export const AnalyticsEngineConfigured: React.FC = () => {
  const dataIsland = useDataIsland();

  const analyticsProps = {
    dataLayer: useDataLayer,
    getDataIsland: () => ({ ...dataIsland }),
    value: {
      ...psxBuyerLandingAnalyticsHandler,
    },
    option: {
      isOptimizelyEdge: true,
      disableGTM: false,
      disableGTMEvents: false,
      disablePixall: false,
    },
  };

  return (
    <AnalyticsEngine {...analyticsProps}/>
  );
}
